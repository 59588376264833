import { red, yellow } from '@material-ui/core/colors';

export const statusColors = {
  good: '#95c11e',
  issues: yellow[500],
  bad: red[500]
};

export const WarnLevels = {
  unsaved_settings: 'info'
};

export const onOffMapping = {
  0: 'off',
  1: 'on'
};

export const offOnMapping = {
  0: 'on',
  1: 'off'
};

export const dayNightCo2PreheatMapping = {
  0: 'day',
  1: 'night',
  2: 'co2',
  3: 'preheat'
};

export const multiOutMapping = {
  0: 'exhaust_fans_ac',
  1: 'intake_fans_ac',
  2: 'humidifier',
  3: 'dehumidifier',
  4: 'lights',
  5: 'interval',
  6: 'heating_mat',
  7: 'chiller',
  8: 'co2_valve',
  9: 'co2_generator',
  10: 'heater',
  11: 'event1',
  12: 'event2',
  13: 'event3',
  14: 'event4',
  15: 'event1+2',
  16: 'event3+4',
  17: 'event1+2+3',
  18: 'event1+2+3+4',
  19: 'disabled',
  20: 'out_en_always',
  21: 'interv_1_inv',
  22: 'interval_2',
  23: 'interv_2_inv',
  24: 'event5',
  25: 'event6',
  26: 'event7',
  27: 'event8',
  28: 'event5+6',
  29: 'event5-8',
  30: 'event1-8',
  31: 'ec_exhaust',
  32: 'ec_intake',
  33: 'dimm_lights_1',
  40: 'pump_irrigation_1',
  41: 'pump_irrigation_2',
  42: 'pump_irrigation_3',
  43: 'circulation_fan_analog',
  44: 'valve_refill',
  45: 'valve_irrigation',
  46: 'valve_circulation_mix',
  50: 'nutrient_pump_1',
  51: 'nutrient_pump_2',
  52: 'nutrient_pump_3',
  53: 'nutrient_pump_4',
  54: 'nutrient_pump_5',
  55: 'nutrient_pump_6',
  56: 'nutrient_pump_7',
  57: 'nutrient_pump_8',
  58: 'ph_down_pump',
  59: 'ph_up_pump',
  60: 'nutrient_ec_in',
  61: 'nutrient_ph_in',
  100: 'tank_min',
  101: 'tank_max',
  110: 'water_detector',
  111: 'smoke_detector'
};

export const multiIOHwTypeMappping = {
  0: 'unknown_hw_type', //0x0u u= do not care
  1: 'digital_out', //0x1u
  2: 'digital_out', //0x2u
  3: 'analog_out', //0x3u
  4: 'mixed_out', //0x4u
  5: 'undefined', //0x5u
  6: 'undefined', //0x6u
  7: 'undefined', //0x7u
  8: 'digital_in', //0x8u
  9: 'analog_in', //0x9u
  10: 'mixed_in', //0xAu
  11: 'io_sensor_in', //0xBu
  12: 'undefined', //0xCu
  13: 'undefined', //0xDu
  14: 'undefined', //0xEu
  15: 'undefined' //0xFu
};

export const multiIOHwTypeMappingSelect = {
  digital_out: [
    'humidifier',
    'dehumidifier',
    'lights',
    'interval',
    'heating_mat',
    'chiller',
    'co2_valve',
    'co2_generator',
    'heater',
    'event1',
    'event2',
    'event3',
    'event4',
    'event1+2',
    'event3+4',
    'event1+2+3',
    'event1+2+3+4',
    'disabled',
    'out_en_always',
    'interv_1_inv',
    'interval_2',
    'interv_2_inv',
    'event5',
    'event6',
    'event7',
    'event8',
    'event5+6',
    'event5-8',
    'event1-8',
    'pump_irrigation_1',
    'pump_irrigation_2',
    'pump_irrigation_3',
    'valve_refill',
    'valve_irrigation',
    'valve_circulation_mix',
    'nutrient_pump_1',
    'nutrient_pump_2',
    'nutrient_pump_3',
    'nutrient_pump_4',
    'nutrient_pump_5',
    'nutrient_pump_6',
    'nutrient_pump_7',
    'nutrient_pump_8',
    'ph_down_pump',
    'ph_up_pump'
  ],
  analog_out: [
    'exhaust_fans_ac',
    'intake_fans_ac',
    'disabled',
    'ec_intake',
    'ec_exhaust',
    'dimm_lights_1',
    'circulation_fan_analog'
  ],
  mixed_out: [
    'exhaust_fans_ac',
    'intake_fans_ac',
    'humidifier',
    'dehumidifier',
    'lights',
    'interval',
    'heating_mat',
    'chiller',
    'co2_valve',
    'co2_generator',
    'heater',
    'event1',
    'event2',
    'event3',
    'event4',
    'event1+2',
    'event3+4',
    'event1+2+3',
    'event1+2+3+4',
    'disabled',
    'out_en_always',
    'interv_1_inv',
    'interval_2',
    'interv_2_inv',
    'event5',
    'event6',
    'event7',
    'event8',
    'event5+6',
    'event5-8',
    'event1-8',
    'pump_irrigation_1',
    'valve_refill',
    'valve_irrigation',
    'valve_circulation_mix',
    'nutrient_pump_1',
    'nutrient_pump_2',
    'nutrient_pump_3',
    'nutrient_pump_4',
    'nutrient_pump_5',
    'nutrient_pump_6',
    'nutrient_pump_7',
    'nutrient_pump_8',
    'ph_down_pump',
    'ph_up_pump'
  ],
  digital_in: ['disabled', 'tank_min', 'tank_max', 'water_detector', 'smoke_detector'],
  analog_in: ['disabled', 'nutrient_ec_in', 'nutrient_ph_in'],
  mixed_in: ['disabled'],
  io_sensor_in: ['disabled'],
  undefined: ['disabled']
};

export const eventGapMapping = {
  0: 'every_day',
  1: 'every_2_day',
  2: 'every_3_day',
  3: 'every_4_day',
  4: 'every_5_day',
  5: 'every_6_day',
  6: 'every_7_day',
  7: 'every_8_day',
  8: 'every_9_day',
  9: 'every_10_day',
  10: 'every_11_day',
  11: 'every_12_day',
  12: 'every_13_day',
  13: 'every_14_day',
  14: 'every_15_day',
  15: 'every_16_day',
  16: 'every_17_day',
  17: 'every_18_day',
  18: 'every_19_day',
  19: 'every_20_day',
  20: 'every_21_day',
  21: 'every_22_day',
  22: 'every_23_day',
  23: 'every_24_day',
  24: 'every_25_day',
  25: 'every_26_day',
  26: 'every_27_day',
  27: 'every_28_day'
};

export const nutritionPumpTypeMapping = {
  0: 'pump_undef',
  1: 'pump_ec',
  2: 'pump_ph_plus',
  3: 'pump_ph_minus'
};

export const nutritionPumpUnitMapping = {
  0: 'nutri_unit_undef',
  1: 'nutri_unit_ml_per_l'
};

export const irrigationStrategyMapping = {
  0: 'irrigation_drain_setpoint',
  1: 'irrigation_vwc_setpoint'
};

export const alarmMapping = {
  alarm_low_temperature_air_day_state: {
    name: 'Low Temperatur Air Day',
    type: 'float',
    unit: '°C'
  },
  alarm_over_temperature_air_day_state: {
    name: 'Over Temperatur Air Day',
    type: 'float',
    unit: '°C'
  },
  alarm_low_temperature_air_night_state: {
    name: 'Low Temperatur Air Night',
    type: 'float',
    unit: '°C'
  },
  alarm_over_temperature_air_night_state: {
    name: 'Over Temperatur Air Night',
    type: 'float',
    unit: '°C'
  },
  alarm_low_temperature_co2_state: { name: 'Low Temperatur CO2', type: 'float', unit: '°C' },
  alarm_over_temperature_co2_state: { name: 'Over Temperatur CO2', type: 'float', unit: '°C' },
  alarm_low_co2_concentration_state: { name: 'Low CO2 Concentration', type: 'co2', unit: 'ppm' },
  alarm_over_co2_concentration_state: { name: 'Over CO2 Concentration', type: 'co2', unit: 'ppm' },
  alarm_low_rh_day_state: { name: 'Low RH Day', type: 'float', unit: '% RH' },
  alarm_over_rh_day_state: { name: 'Over RH Day', type: 'float', unit: '% RH' },
  alarm_low_rh_night_state: { name: 'Low RH Night', type: 'float', unit: '% RH' },
  alarm_over_rh_night_state: { name: 'Over RH Night', type: 'float', unit: '% RH' },
  alarm_low_vpd_state: { name: 'Low VPD Day', type: 'float', unit: 'hPa' },
  alarm_over_vpd_state: { name: 'Over VPD Day', type: 'float', unit: 'hPa' },
  alarm_low_vpd_night_state: { name: 'Low VPD Night', type: 'float', unit: 'hPa' },
  alarm_over_vpd_night_state: { name: 'Over VPD Night', type: 'float', unit: 'hPa' },
  alarm_no_connection_to_main_controller: {
    name: 'No active connection to controller',
    type: 'bool',
    unit: ''
  },
  alarm_sensor_error_rht_state: { name: 'Sensor Error RHT', type: 'bool', unit: '' },
  alarm_sensor_error_co2_state: { name: 'Sensor Error Co2', type: 'bool', unit: '' },
  alarm_sensor_error_pressure_state: { name: 'Sensor Error Pressure', type: 'bool', unit: '' },
  alarm_sensor_error_heating_mat_state: {
    name: 'Sensor Error Heating Mat',
    type: 'bool',
    unit: ''
  },
  alarm_sensor_error_leaf_state: { name: 'Sensor Error Leaf', type: 'bool', unit: '' },
  alarm_error_power_extender_state: { name: 'Sensor Error Power Extender', type: 'bool', unit: '' },
  alarm_sensor_error_intake_enable: { name: 'Sensor Error Intake', type: 'bool', unit: '' }
};

export const alarm_threshold = {
  temp: 2,
  co2: 100,
  rh: 5,
  vpd: 2
};

// Infos
// undefined means the values depends on other values and is computed on the page directly.
export const settings_borders_and_units = {
  humidity_sp_day: [10, 95, '  % RH'],
  humidity_sp_night: [10, 95, '  % RH'],
  rh_sp_day: [10, 95, '  % RH'], // scheduling
  rh_sp_night: [10, 95, '  % RH'], // scheduling

  temp_sp_day: [10, 50, '  °C'],
  temp_sp_night: [10, 50, '  °C'],
  temp_main_sp_day: [10, 50, '  °C'], // scheduling
  temp_main_sp_night: [10, 50, '  °C'], // scheduling

  vpd_sp: [1, 20, '  hPa'],
  vpd_sp_night: [1, 20, '  hPa'],
  vpd_sp_day: [1, 20, '  hPa'], // scheduling

  temp_sp_heater_day: [4.9, undefined, '  °C'],
  temp_sp_heater_night: [5, undefined, '  °C'],
  temp_heater_sp_day: [4.9, undefined, '  °C'], // scheduling
  temp_heater_sp_night: [5, undefined, '  °C'], // scheduling

  temp_sp_heating_mat_day_rel: [0, 10, '  °C'],
  temp_sp_heating_mat_night_rel: [0, 10, '  °C'],
  temp_sp_heating_mat_day_abs: [5, 40, '  °C'],
  temp_sp_heating_mat_night_abs: [5, 40, '  °C'],
  temp_heating_mat_day_rel: [0, 10, '  °C'], // scheduling
  temp_heating_mat_night_rel: [0, 10, '  °C'], // scheduling
  temp_heating_mat_day_abs: [5, 40, '  °C'], // scheduling
  temp_heating_mat_night_abs: [5, 40, '  °C'], // scheduling

  preheat_night_day: [0, 120, '  min'],

  // co2-settings
  co2_sp: [500, 1800, '  ppm'],
  co2_concentr_sp: [500, 1800, '  ppm'],
  room_vol_sp: [0.1, 6500, '  m³'],
  co2_flow: [2, 100, ' l/min'],
  exhaust_fans_flow: [0, 60000, ' m³/h'],
  temp_sp_co2: [10, 50, ' °C'],
  temp_heater_co2: [4.9, undefined, ' °C'],
  temp_main_sp_co2: [10, 50, ' °C'], // scheduling
  temp_heater_sp_co2: [4.9, undefined, ' °C'], // scheduling

  // EC fans co2
  exhaust_fan_ec_max_co2: [undefined, undefined, ' %'],
  exhaust_fan_ec_min_co2: [undefined, undefined, ' %'],
  intake_fan_ec_max_co2: [undefined, undefined, ' %'],
  intake_fan_ec_min_co2: [undefined, undefined, ' %'],
  // AC fans co2
  exhaust_fan_ac_max_co2: [undefined, undefined, ' %'],
  exhaust_fan_ac_min_co2: [undefined, undefined, ' %'],
  intake_fan_ac_max_co2: [undefined, undefined, ' %'],
  intake_fan_ac_min_co2: [undefined, undefined, ' %'],

  // ventilation-settings
  // EC fans day/night
  exhaust_fan_ec_max_day: [undefined, undefined, ' %'],
  exhaust_fan_ec_min_day: [undefined, undefined, ' %'],
  exhaust_fan_ec_max_night: [undefined, undefined, ' %'],
  exhaust_fan_ec_min_night: [undefined, undefined, ' %'],
  intake_fan_ec_max_night: [undefined, undefined, ' %'],
  intake_fan_ec_min_night: [undefined, undefined, ' %'],
  intake_fan_ec_max_day: [undefined, undefined, ' %'],
  intake_fan_ec_min_day: [undefined, undefined, ' %'],
  // AC fans day/night
  exhaust_fan_ac_max_day: [undefined, undefined, ' %'],
  exhaust_fan_ac_min_day: [undefined, undefined, ' %'],
  exhaust_fan_ac_max_night: [undefined, undefined, ' %'],
  exhaust_fan_ac_min_night: [undefined, undefined, ' %'],
  intake_fan_ac_max_night: [undefined, undefined, ' %'],
  intake_fan_ac_min_night: [undefined, undefined, ' %'],
  intake_fan_ac_max_day: [undefined, undefined, ' %'],
  intake_fan_ac_min_day: [undefined, undefined, ' %'],

  deviation_rpm_reduction: [0, 10, ' °C'],
  pressure_percent_day: [-50, 100, ' %'],
  pressure_percent_night: [-50, 100, ' %'],
  pressure_pa: [2, 100, ' Pa'],

  lights_max: [10, 115, ' %'],
  lights_min: [9, 100, ' %'],
  light_sunrise_duration: [0, 90, ' min'],
  lights_dim_over_temp_temp_day: [undefined, 50, ' °C'],
  lights_dim_over_temp_temp_co2: [undefined, 50, ' °C'],
  lights_dim_over_temp_reduct_ph1: [undefined, undefined, ' %'],
  lights_dim_over_temp_reduct_ph2: [undefined, undefined, ' %'],
  lights_dim_over_temp_reduct_ph3: [undefined, undefined, ' %'],
  lights_dim_over_temp_reduct_wait_minute: [1, 60, ' min'],

  fan_ac_intake_turnoff: [10, 80, ' %'],
  fan_ac_exhaust_turnoff: [10, 80, ' %'],
  intake_sens_min_diff_rh: [0, 25, ' % RH'],
  intake_sens_min_diff_temp: [0, 10, ' °C'],
  intake_sens_retry_wait: [0, 300, 'min'],
  intake_sens_retry_duration: [0, 300, 'sec'],
  rh_min_with_vpd: [50, undefined, ' % RH'],
  rh_max_with_vpd: [undefined, 950, ' % RH'],

  leaf_temp_diff: [0, 7, ' °C'],
  hysteresis_co2: [30, 200, ' ppm'],
  reg_kd_pressure: [1, 50, ''],
  reg_kd_temp_air: [1, 50, ''],
  interval_ramp_temp_sp: [1, 120, ' min'],
  hysteresis_humidifier: [0.2, 30, ' (% RH)'],
  humidifier_kd: [0, 500, ''],
  hysteresis_dehumidifier: [1, 30, ' (% RH)'],
  offset_sp_dehumidifier: [-8, 8, ' % RH'],
  hysteresis_heater: [0.1, 5, ' °C'],
  hysteresis_chiller_low: [0.5, 10, ' °C'],
  hysteresis_chiller_high: [0.5, 10, ' °C'],
  co2_min_dose_ms: [100, 5000, ' ms'],
  lights_dim_over_temp_hyst: [0, 5, ' °C'],
  lights_dim_over_temp_delay_sec: [10, 600, ' sec'],
  offset_sp_humidifier: [-8, 8, ' % RH'],
  offset_sp_humidifier_night: [-8, 8, ' % RH'],
  offset_sp_dehumidifier_night: [-8, 8, ' % RH'],
  dehumid_exhaust_threshold: [0, 25, ' °C'],
  dehumid_exhaust_temp_untercut_max_allowed: [1, 21, ' °C'],

  filter_val_sensor_co2: [0, 7, ''],
  calib_val_sensor_co2: [0, 2000, ' ppm'],

  // Alarms
  alarm_low_temperature_air_day_setting: [5, undefined, ' °C'], // undefined set in html code
  alarm_over_temperature_air_day_setting: [undefined, 50, ' °C'],
  alarm_low_temperature_air_night_setting: [5, undefined, ' °C'],
  alarm_over_temperature_air_night_setting: [undefined, 50, ' °C'],
  alarm_low_temperature_air_co2_setting: [5, undefined, ' °C'],
  alarm_over_temperature_air_co2_setting: [undefined, 50, ' °C'],
  alarm_low_co2_concentration_setting: [300, undefined, ' ppm'],
  alarm_over_co2_concentration_setting: [undefined, 5000, ' ppm'],
  alarm_low_rh_day_setting: [10, undefined, ' % RH'],
  alarm_over_rh_day_setting: [undefined, 95, ' % RH'],
  alarm_low_rh_night_setting: [0, undefined, ' % RH'],
  alarm_over_rh_night_setting: [undefined, 95, ' % RH'],
  alarm_low_vpd_setting: [2, undefined, ' hPa'],
  alarm_over_vpd_setting: [undefined, 50, ' hPa'],
  alarm_low_vpd_night_setting: [2, undefined, ' hPa'],
  alarm_over_vpd_night_setting: [undefined, 50, ' hPa'],
  expected_power_extender_out_cnt: [1, 20, ''],

  // timing
  cur_grow_cycle_day: [1, 100, ''],

  // nutrients
  ec_setpoint: [0, 5, ' mS/cm'],
  ph_setpoint: [3, 10, ''],
  temp_setpoint: [10, 30, ' °C'],
  value: [0, 20, ' ml/l'],

  // irrigation
  irrigation_p0_dry_back_abs: [1, 50, ' %VWC'],
  irrigation_p0_phase_timeout_minutes: [30, 240, ' min'],
  irrigation_p1_shot_size_vwc_rel: [10, 80, ' %VWC'],
  irrigation_p1_target_vwc_abs: [10, 80, ' %VWC'],
  irrigation_p1_dry_back_rel: [1, 10, ' %VWC'],
  irrigation_p1_wait_after_shot_minutes: [1, 10, ' min'],
  irrigation_p1_phase_timeout_minute: [30, 240, ' min'],
  irrigation_p1_shot_duration_timeout_minutes: [1, 20, ' min'],
  irrigation_p2_drain_sp: [1, 30, ' %'],
  irrigation_p2_target_vwc_abs: [10, 80, ' %VWC'],
  irrigation_p2_dry_back_abs: [1, 10, ' %VWC'],
  irrigation_p2_shot_duration_timeout_minutes: [1, 60, ' min'],
  irrigation_p2_wait_after_shot_s: [1, 60, ' min'],
  irrigation_p3_min_vwc_abs: [1, 50, ' %VWC'],
  irrigation_p3_shot_size_rel: [1, 10, ' %VWC'],
  irrigation_p3_min_wait_after_shot: [30, 240, ' min']
};

export function GetMinBorder(min, id) {
  id = id.indexOf(':') > -1 ? id.split(':')[1] : id;
  if (min === undefined && id in settings_borders_and_units)
    return settings_borders_and_units[id][0];
  return min;
}

export function GetMaxBorder(max, id) {
  id = id.indexOf(':') > -1 ? id.split(':')[1] : id;
  if (max === undefined && id in settings_borders_and_units)
    return settings_borders_and_units[id][1];
  return max;
}

export function GetUnit(unit, id) {
  id = id.indexOf(':') > -1 ? id.split(':')[1] : id;
  if (unit === undefined && id in settings_borders_and_units)
    return settings_borders_and_units[id][2];
  return unit;
}

export const HISTORY_VALUES = [
  'humidity',
  'temp',
  'co2',
  'vpd',
  'temp_leaf_current',
  'vol_water_content',
  'ec_root_zone'
];

export const room_scale_mapping = {
  room_vol_sp: { 0: 30, 1: 1000, 2: 6500 },
  exhaust_fans_flow: { 0: 3000, 1: 10000, 2: 60000 },
  co2_flow: { 0: 20, 1: 50, 2: 999.9 }
};

export const controller_type_mapping = {
  5053: 'GrowBase',
  5055: 'GrowBase Pro',
  6000: 'Industrial',
  5052: 'GrowBase Lite',
  5155: 'Industrial Lite'
};

export const resolutions = {
  recent: 4, // 4s
  daily: 240, // 4min
  full: 1800 // 30min
};

export const number_of_time_points = {
  4: 150, // 10min
  240: 288, // 24h
  1800: 6048 // 18 weeks
};

export const firmware_version_infos = {
  5055: [
    {
      text: '5055.0.01.00b DE',
      link: 'https://docs.growcontrol.de/fw505500100b_GrowBase_Pro_WiFi_Beta_Release_Note_DE.pdf'
    },
    {
      text: '5055.0.01.00b EN',
      link: 'https://docs.growcontrol.de/fw505500100b_GrowBase_Pro_WiFi_Beta_Release_Note_EN.pdf'
    }
  ],
  5053: [
    {
      text: '5053.0.01.00b DE',
      link: 'https://docs.growcontrol.de/fw505300100b_GrowBase_WiFi_Beta_Release_Note_DE.pdf'
    },
    {
      text: '5053.0.01.00b EN',
      link: 'https://docs.growcontrol.de/fw505300100b_GrowBase_WiFi_Beta_Release_Note_EN.pdf'
    }
  ],
  5052: [
    {
      text: '5052.0.01.00b DE',
      link: 'https://docs.growcontrol.de/fw505200100b_GrowBase_WiFi_Beta_Release_Note_DE.pdf'
    },
    {
      text: '5052.0.01.00b EN',
      link: 'https://docs.growcontrol.de/fw505200100b_GrowBase_WiFi_Beta_Release_Note_EN.pdf'
    }
  ],
  5155: [
    {
      text: '5155.0.01.00b DE',
      link: 'https://docs.growcontrol.de/fw515500100b_Release_Note_DE.pdf'
    },
    {
      text: '5155.0.01.00b EN',
      link: 'https://docs.growcontrol.de/fw515500100b_Release_Note_EN.pdf'
    }
  ]
};

export const initialRequests = [
  'request_values_full',
  'get_full_alarm_log',
  'get_all_messages',
  'get_recent_history',
  'get_info_graphs'
];

export const NUM_PHASES = 10;
