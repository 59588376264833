/*
 * These setting currently work for:
 * Headlines: SettingGroup, SettingGroupGrid
 * Values: ShowSettings, ShowSettingOnOff, ShowSettingMinMax, ValueBox
 *
 * These settings do not apply for iterated fields such as multi-out values,
 * these have to be handled seperatly
 */
export const controller_type_exclude_features = {
  5052: {
    tabs: ['scheduling', 'nutrition', 'irrigation'],
    headlines: [
      // info tab
      'root_zone_hl',
      'nutrients_hl',
      // climate tab
      'vapour_pressure_deficit_vpd',
      'preheat_night_day',
      'temp_sp_heater',
      // system tab
      'multi_sensor_handling',
      // alarms tab
      'alarm_vpd_day_hl',
      'alarm_vpd_night_hl',
      'alarm_additional_errors',
      // advanced tab
      'hysteresis_heater',
      // scheduling tab
      'interval_2_day',
      'interval_2_night'
    ],
    values: [
      // info tab
      'info_vpd',
      'info_temp_leaf_current',
      'info_rh_intake_current',
      'info_temp_intake_current',
      'info_interval_2_timer',
      'info_temp_heater_setpoint_info',
      // climate tab
      'climate_heating_to_dehumidify_en',
      // co2 tab
      'temp_heater_co2',
      // alarms tab
      'alarm_sensor_error_leaf_enable',
      'alarm_sensor_error_intake_enable',
      // advanced tab
      'intake_sens_min_diff_rh',
      'intake_sens_min_diff_temp',
      'intake_sens_retry_wait',
      'intake_sens_retry_duration',
      'rh_max_with_vpd',
      // scheduling tab
      'temp_heater_sp_co2'
    ]
  },
  5053: {
    tabs: ['scheduling', 'nutrition', 'irrigation'],
    headlines: [
      // info tab
      'root_zone_hl',
      'nutrients_hl',
      // climate tab
      'vapour_pressure_deficit_vpd',
      'preheat_night_day',
      'temp_sp_heater',
      // system tab
      'multi_sensor_handling',
      // alarms tab
      'alarm_vpd_day_hl',
      'alarm_vpd_night_hl',
      'alarm_additional_errors',
      // advanced tab
      'hysteresis_heater',
      // scheduling tab
      'interval_2_day',
      'interval_2_night'
    ],
    values: [
      // info tab
      'info_vpd',
      'info_temp_leaf_current',
      'info_rh_intake_current',
      'info_temp_intake_current',
      'info_interval_2_timer',
      'info_temp_heater_setpoint_info',
      // climate tab
      'climate_heating_to_dehumidify_en',
      // co2 tab
      'temp_heater_co2',
      // alarms tab
      'alarm_sensor_error_leaf_enable',
      'alarm_sensor_error_intake_enable',
      // advanced tab
      'intake_sens_min_diff_rh',
      'intake_sens_min_diff_temp',
      'intake_sens_retry_wait',
      'intake_sens_retry_duration',
      'rh_max_with_vpd',
      // scheduling tab
      'temp_heater_sp_co2'
    ]
  },
  5055: {
    tabs: ['scheduling', 'nutrition', 'irrigation'],
    headlines: [
      // info tab
      'root_zone_hl',
      'nutrients_hl',
      // climate tab
      'climate_heating_to_dehumidify_en'
    ],
    values: []
  },
  5155: {
    tabs: ['scheduling'],
    headlines: [],
    values: []
  },
  6000: {
    tabs: ['scheduling'],
    headlines: [],
    values: []
  }
};

export const controller_project_exclude_features = {
  1: {
    tabs: ['co2', 'ventilation', 'multi_out', 'multi_io', 'advanced'],
    headlines: [
      // info
      'root_zone_hl',
      'nutrients_hl',
      // climate
      'temp_sp_heater',
      'temperature_setpoint_heating_mat',
      'dehumidification_hl',
      // timing
      'interval_day',
      'interval_night',
      // system
      'controller_menu_settings',
      'multi_sensor_handling',
      'multi_sensor_handling',
      'sensor_calibration_hl',
      // alarms
      'alarm_temperature_co2',
      'alarm_co2_concentration',
      'sensor_errors'
    ],
    values: [
      // info
      'info_rh_intake_current',
      'info_temp_intake_current',
      'info_temp_heating_mat',
      'info_neg_pressure',
      'info_interval_timer',
      'info_interval_2_timer',
      'info_ec_fans_intake',
      'info_ec_fans_exhaust'
    ]
  }
};
