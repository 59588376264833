import { Ran } from './utils.js';

const GCSPI_MAX_MULTI_IO_CNT = 54;

export function CreateSimulationInit(socket, display_name, type, username) {
  var req = { command: 'mock_initialize', cookie: document.cookie, data: {} };
  req.socket = socket;
  req.data['display_name'] = display_name;
  req.data['type'] = type;
  req.data['mac_address'] = Ran(0, 9) + '-' + Ran(0, 9) + '-' + Ran(0, 9) + '-' + Ran(9, 0);
  req.data['project_id'] = type === 5155 ? 1 : 0;
  req.data['version'] = '#latest';
  req['internal_id'] = display_name.toLowerCase().replace(' ', '_');
  return req;
}

export function SimuInfoGraph(ub, lb) {
  let graph = [lb];
  for (let i = 0; i < 180; i++) {
    const last_value = graph[graph.length - 1];
    const lower = 5 - (last_value - lb <= 5) ? last_value - lb : 0;
    const upper = 5 - (ub - last_value <= 5) ? ub - last_value : 0;
    graph.push(last_value + Ran(-lower, upper));
  }
  return graph;
}

// *** SendValues *** //
export function SimulationGetSendValueRequest(request_values_full, internal_id) {
  // Initialize json request.
  const date = new Date();
  var json_request = {
    internal_id: internal_id,
    command: 'update_values',
    data: { timestamp: date.getTime() }
  };

  // Create random values
  json_request.data.cur_values = {};
  json_request.data.cur_values['humidity'] = Ran(598, 602);
  json_request.data.cur_values['temp'] = Ran(269, 271);
  json_request.data.cur_values['vpd'] = Ran(69, 71);
  json_request.data.cur_values['temp_leaf_current'] = Ran(238, 243);
  json_request.data.cur_values['co2'] = Ran(1400, 1500);
  json_request.data.cur_values['neg_pressure'] = 3999;
  json_request.data.cur_values['temp_heating_mat'] = Ran(279, 281);
  json_request.data.cur_values['humidity_max'] = Ran(602, 602);
  json_request.data.cur_values['humidity_min'] = Ran(598, 598);
  json_request.data.cur_values['temp_max'] = Ran(260, 260);
  json_request.data.cur_values['temp_min'] = Ran(258, 258);
  json_request.data.cur_values['info_interval_timer_active'] = Ran(0, 1);
  json_request.data.cur_values['info_interval_timer'] = '00:00:0' + Ran(0, 1);
  json_request.data.cur_values['info_interval_2_timer_active'] = Ran(0, 1);
  json_request.data.cur_values['info_interval_2_timer'] = '00:0' + Ran(0, 1) + ':00';

  json_request.data.cur_values['info_lights'] = 100;
  json_request.data.cur_values['time'] = new Date().toLocaleTimeString('de-DE').split(' ')[0]; //"00:00:00"; // "14:55:50";
  json_request.data.cur_values['date'] = '05.03.2020'; // "28.09.2020";
  json_request.data.cur_values['phase'] = Ran(1, 1);
  json_request.data.cur_values['cur_day'] = 10;
  json_request.data.cur_values['info_temp_sp'] = Ran(270, 270);
  json_request.data.cur_values['info_humidity_sp'] = Ran(600, 600);
  json_request.data.cur_values['temp_heater_setpoint_info'] = Ran(220, 220);
  json_request.data.cur_values['rh_intake_current'] = Ran(420, 420);
  json_request.data.cur_values['temp_intake_current'] = Ran(210, 210);

  // Version AC
  json_request.data.cur_values['vol_water_content'] = Ran(520, 530);
  json_request.data.cur_values['ec_root_zone'] = Ran(2400, 2500);
  json_request.data.cur_values['temp_root_zone'] = Ran(243, 244);
  json_request.data.cur_values['ec_nutrient_solution'] = Ran(1400, 1500);
  json_request.data.cur_values['ph_nutrient_solution'] = Ran(7100, 7200);
  json_request.data.cur_values['temp_nutrient_solution'] = Ran(243, 244);

  json_request.data.ec_fans = {};
  json_request.data.ec_fans['ec_fans_intake_out'] = Ran(30, 31);
  json_request.data.ec_fans['ec_fans_intake_rpm'] = Ran(1202, 1205);
  json_request.data.ec_fans['ec_fans_exhaust_out'] = Ran(40, 41);
  json_request.data.ec_fans['ec_fans_exhaust_rpm'] = Ran(1448, 1450);

  json_request.data.multi_out_values = {};
  json_request.data.multi_out_values['out_count'] = 12;
  json_request.data.multi_out_values['out_' + 1 + '_state'] = Ran(30, 31);
  json_request.data.multi_out_values['out_' + 2 + '_state'] = Ran(40, 41);
  json_request.data.multi_out_values['out_' + 3 + '_state'] = 0;
  json_request.data.multi_out_values['out_' + 4 + '_state'] = 1;
  json_request.data.multi_out_values['out_' + 5 + '_state'] = 1;
  for (var i = 6; i <= 25; i++) {
    // TODO: Select different ranges, depending in function.
    json_request.data.multi_out_values['out_' + i + '_state'] = Ran(50, 65); // 0,1 => value %
  }

  // Version ACAB
  json_request.data.multi_io_infos = [];
  for (let i = 0; i < GCSPI_MAX_MULTI_IO_CNT; i++) {
    let io = {};
    io['status'] = Ran(30, 31);
    io['info'] = Ran(40, 41);
    io['info_type'] = Ran(50, 51);
    json_request.data.multi_io_infos.push(io);
  }

  if (request_values_full === true) {
    json_request.data.climate = {};
    json_request.data.climate['humidity_sp_day'] = Ran(10, 95);
    json_request.data.climate['humidity_sp_night'] = Ran(10, 95);
    json_request.data.climate['temp_sp_day'] = Ran(270, 270);
    json_request.data.climate['temp_sp_night'] = Ran(200, 200);
    json_request.data.climate['vpd_sp'] = Ran(70, 70);
    json_request.data.climate['vpd_sp_night'] = Ran(100, 100);
    json_request.data.climate['vpd_calc_enable'] = Ran(0, 2);
    json_request.data.climate['temp_sp_heater_day'] = Ran(200, 200);
    json_request.data.climate['temp_sp_heater_night'] = Ran(160, 160);
    json_request.data.climate['toggle_temp_heating_mat_day'] = Ran(1, 1);
    json_request.data.climate['temp_sp_heating_mat_day_abs'] = Ran(300, 300);
    json_request.data.climate['temp_sp_heating_mat_day_rel'] = Ran(30, 30);
    json_request.data.climate['toggle_temp_heating_mat_night'] = Ran(1, 1);
    json_request.data.climate['temp_sp_heating_mat_night_abs'] = Ran(230, 230);
    json_request.data.climate['temp_sp_heating_mat_night_rel'] = Ran(30, 30);

    json_request.data.climate['dehumidification_mode'] = Ran(1, 1);
    json_request.data.climate['heating_to_dehumidify_en'] = 0;
    json_request.data.climate['preheat_night_day'] = Ran(0, 0);

    // CO2-Settings
    json_request.data.co2 = {};
    json_request.data.co2['co2_control'] = Ran(0, 0);
    json_request.data.co2['co2_sp'] = Ran(1200, 1200);
    json_request.data.co2['room_vol_sp'] = Ran(100, 100);
    json_request.data.co2['co2_flow'] = Ran(80, 80);
    json_request.data.co2['exhaust_fans_flow'] = Ran(1450, 1450);
    json_request.data.co2['temp_sp_co2'] = Ran(320, 320);
    // Fans EC co2
    json_request.data.co2['exhaust_fan_ec_max_co2'] = Ran(70, 100);
    json_request.data.co2['exhaust_fan_ec_min_co2'] = Ran(10, 30);
    json_request.data.co2['intake_fan_ec_max_co2'] = Ran(70, 100);
    json_request.data.co2['intake_fan_ec_min_co2'] = Ran(10, 30);
    // Fans AC co2
    json_request.data.co2['exhaust_fan_ac_max_co2'] = Ran(70, 100);
    json_request.data.co2['exhaust_fan_ac_min_co2'] = Ran(10, 30);
    json_request.data.co2['intake_fan_ac_max_co2'] = Ran(70, 100);
    json_request.data.co2['intake_fan_ac_min_co2'] = Ran(10, 30);

    json_request.data.co2['room_scale'] = Ran(0, 2);
    json_request.data.co2['temp_heater_co2'] = Ran(60, json_request.data.co2['temp_sp_co2'] - 10);

    // Ventilation
    json_request.data.ventilation = {};
    json_request.data.ventilation['pressure_percent_day'] = 137; // Ran(100,100);
    json_request.data.ventilation['pressure_percent_night'] = 137; // Ran(100, 100);
    // Fans EC day/night
    json_request.data.ventilation['exhaust_fan_ec_min_day'] = Ran(10, 30);
    json_request.data.ventilation['exhaust_fan_ec_max_day'] = Ran(70, 100);
    json_request.data.ventilation['exhaust_fan_ec_min_night'] = Ran(10, 30);
    json_request.data.ventilation['exhaust_fan_ec_max_night'] = Ran(70, 100);
    json_request.data.ventilation['intake_fan_ec_max_day'] = Ran(70, 100);
    json_request.data.ventilation['intake_fan_ec_max_night'] = Ran(70, 100);
    json_request.data.ventilation['intake_fan_ec_min_day'] = Ran(10, 30);
    json_request.data.ventilation['intake_fan_ec_min_night'] = Ran(10, 30);
    // Fans AC day/night
    json_request.data.ventilation['exhaust_fan_ac_min_day'] = Ran(10, 30);
    json_request.data.ventilation['exhaust_fan_ac_max_day'] = Ran(70, 100);
    json_request.data.ventilation['exhaust_fan_ac_min_night'] = Ran(10, 30);
    json_request.data.ventilation['exhaust_fan_ac_max_night'] = Ran(70, 100);
    json_request.data.ventilation['intake_fan_ac_max_day'] = Ran(70, 100);
    json_request.data.ventilation['intake_fan_ac_max_night'] = Ran(70, 100);
    json_request.data.ventilation['intake_fan_ac_min_day'] = Ran(10, 30);
    json_request.data.ventilation['intake_fan_ac_min_night'] = Ran(10, 30);

    json_request.data.ventilation['deviation_rpm_reduction'] = Ran(2, 2);
    json_request.data.ventilation['pressure_pa'] = Ran(5, 5);
    json_request.data.ventilation['pressure_auto_cal'] = Ran(0, 0);

    json_request.data.timing = {};
    json_request.data.timing['time_cont_hour'] = Ran(0, 23);
    json_request.data.timing['time_cont_min'] = Ran(0, 59);
    json_request.data.timing['time_cont_sec'] = Ran(0, 59);
    json_request.data.timing['time_cont_day'] = Ran(1, 31);
    json_request.data.timing['time_cont_month'] = Ran(1, 12);
    json_request.data.timing['time_cont_year'] = Ran(2020, 2021);
    json_request.data.timing['start_day_hour'] = Ran(6, 6);
    json_request.data.timing['start_day_min'] = Ran(0, 0);
    json_request.data.timing['end_day_hour'] = Ran(18, 18);
    json_request.data.timing['end_day_min'] = Ran(0, 0);
    json_request.data.timing['interval_on_day_hour'] = 0; //Ran(0,23);
    json_request.data.timing['interval_on_day_min'] = 2; //Ran(0,59);
    json_request.data.timing['interval_on_day_sec'] = 0; //Ran(0,59);
    json_request.data.timing['interval_off_day_hour'] = 0; //Ran(0,23);
    json_request.data.timing['interval_off_day_min'] = 15; //Ran(0,59);
    json_request.data.timing['interval_off_day_sec'] = 0; //Ran(0,59);
    json_request.data.timing['interval_on_night_hour'] = 0; //Ran(0,23);
    json_request.data.timing['interval_on_night_min'] = 1; //Ran(0,59);
    json_request.data.timing['interval_on_night_sec'] = 0; //Ran(0,59);
    json_request.data.timing['interval_off_night_hour'] = 0; //Ran(0,23);
    json_request.data.timing['interval_off_night_min'] = 30; //Ran(0,59);
    json_request.data.timing['interval_off_night_sec'] = 0; //Ran(0,59);
    json_request.data.timing['event1_start_hour'] = 14; //Ran(0,23);
    json_request.data.timing['event1_start_min'] = 6; //Ran(0,59);
    json_request.data.timing['event1_duration_hour'] = 0; //Ran(0,23);
    json_request.data.timing['event1_duration_min'] = 0; //Ran(0,59);
    json_request.data.timing['event1_duration_sec'] = 0; //Ran(0,59);
    json_request.data.timing['event2_start_hour'] = 0; //14;//Ran(0,23);
    json_request.data.timing['event2_start_min'] = 6; //Ran(0,59);
    json_request.data.timing['event2_duration_hour'] = 0; //Ran(0,23);
    json_request.data.timing['event2_duration_min'] = 0; //Ran(0,59);
    json_request.data.timing['event2_duration_sec'] = 0; //Ran(0,59);
    json_request.data.timing['event3_start_hour'] = 14; //Ran(0,23);
    json_request.data.timing['event3_start_min'] = 6; //Ran(0,59);
    json_request.data.timing['event3_duration_hour'] = 0; //Ran(0,23);
    json_request.data.timing['event3_duration_min'] = 0; //Ran(0,59);
    json_request.data.timing['event3_duration_sec'] = 0; //Ran(0,59);
    json_request.data.timing['event4_start_hour'] = 14; //Ran(0,23);
    json_request.data.timing['event4_start_min'] = 6; //Ran(0,59);
    json_request.data.timing['event4_duration_hour'] = 0; //Ran(0,23);
    json_request.data.timing['event4_duration_min'] = 0; //Ran(0,59);
    json_request.data.timing['event4_duration_sec'] = 0; //Ran(0,59);
    json_request.data.timing['event5_start_hour'] = 14; //Ran(0,23);
    json_request.data.timing['event5_start_min'] = 6; //Ran(0,59);
    json_request.data.timing['event5_duration_hour'] = 0; //Ran(0,23);
    json_request.data.timing['event5_duration_min'] = 0; //Ran(0,59);
    json_request.data.timing['event5_duration_sec'] = 0; //Ran(0,59);
    json_request.data.timing['event6_start_hour'] = 14; //Ran(0,23);
    json_request.data.timing['event6_start_min'] = 6; //Ran(0,59);
    json_request.data.timing['event6_duration_hour'] = 0; //Ran(0,23);
    json_request.data.timing['event6_duration_min'] = 0; //Ran(0,59);
    json_request.data.timing['event6_duration_sec'] = 0; //Ran(0,59);
    json_request.data.timing['event7_start_hour'] = 14; //Ran(0,23);
    json_request.data.timing['event7_start_min'] = 6; //Ran(0,59);
    json_request.data.timing['event7_duration_hour'] = 0; //Ran(0,23);
    json_request.data.timing['event7_duration_min'] = 0; //Ran(0,59);
    json_request.data.timing['event7_duration_sec'] = 0; //Ran(0,59);
    json_request.data.timing['event8_start_hour'] = 14; //Ran(0,23);
    json_request.data.timing['event8_start_min'] = 6; //Ran(0,59);
    json_request.data.timing['event8_duration_hour'] = 0; //Ran(0,23);
    json_request.data.timing['event8_duration_min'] = 0; //Ran(0,59);
    json_request.data.timing['event8_duration_sec'] = 0; //Ran(0,59);

    json_request.data.timing['time_event1_exec_gap'] = 0;
    json_request.data.timing['time_event2_exec_gap'] = 0;
    json_request.data.timing['time_event3_exec_gap'] = 0;
    json_request.data.timing['time_event4_exec_gap'] = 0;
    json_request.data.timing['time_event5_exec_gap'] = 0;
    json_request.data.timing['time_event6_exec_gap'] = 0;
    json_request.data.timing['time_event7_exec_gap'] = 0;
    json_request.data.timing['time_event8_exec_gap'] = 0;

    json_request.data.timing['time_interval_2_on_day_hour'] = 0;
    json_request.data.timing['time_interval_2_on_day_minute'] = 2;
    json_request.data.timing['time_interval_2_on_day_sec'] = 0;
    json_request.data.timing['time_interval_2_off_day_hour'] = 0;
    json_request.data.timing['time_interval_2_off_day_minute'] = 15;
    json_request.data.timing['time_interval_2_off_day_sec'] = 0;
    json_request.data.timing['time_interval_2_on_night_hour'] = 0;
    json_request.data.timing['time_interval_2_on_night_minute'] = 1;
    json_request.data.timing['time_interval_2_on_night_sec'] = 0;
    json_request.data.timing['time_interval_2_off_night_hour'] = 0;
    json_request.data.timing['time_interval_2_off_night_minute'] = 30;
    json_request.data.timing['time_interval_2_off_night_sec'] = 0;

    json_request.data.lights = {};
    json_request.data.lights['lights_max'] = 100; //Ran(10,115);
    json_request.data.lights['lights_min'] = 10; //Ran(9,100);
    json_request.data.lights['light_sunrise_duration'] = 5; //Ran(0,90);
    json_request.data.lights['lights_dim_over_temp_enable'] = 0;
    json_request.data.lights['lights_dim_over_temp_temp_day'] = 300;
    json_request.data.lights['lights_dim_over_temp_temp_co2'] = 350;
    json_request.data.lights['lights_dim_over_temp_reduct_ph1'] = 70;
    json_request.data.lights['lights_dim_over_temp_reduct_ph2'] = 30;
    json_request.data.lights['lights_dim_over_temp_reduct_ph3'] = 9;
    json_request.data.lights['lights_dim_over_temp_reduct_wait_minute'] = 3;

    json_request.data.advanced = {};
    json_request.data.advanced['fan_ac_intake_turnoff'] = 25; //Ran(12,20);
    json_request.data.advanced['fan_ac_exhaust_turnoff'] = 25; //an(12,20);

    json_request.data.advanced['leaf_temp_diff'] = 30; //Ran(0,70);
    json_request.data.advanced['hysteresis_co2'] = 15; //Ran(1,2000);
    json_request.data.advanced['reg_kd_pressure'] = 8; //Ran(1,50);
    json_request.data.advanced['reg_kd_temp_air'] = 15; //Ran(1,50);
    json_request.data.advanced['interval_ramp_temp_sp'] = 15; //Ran(2,600);
    json_request.data.advanced['reg_type_humidifier'] = 1; //Ran(0,1);
    json_request.data.advanced['hysteresis_humidifier'] = 15; //Ran(2,300);
    json_request.data.advanced['humidifier_kd'] = 160; //Ran(100,400);
    json_request.data.advanced['reg_type_dehumidifier'] = 0; //Ran(0,1);
    json_request.data.advanced['hysteresis_dehumidifier'] = 40; //Ran(2,300);
    json_request.data.advanced['offset_sp_dehumidifier'] = -40; //Ran(2,30);
    json_request.data.advanced['hysteresis_heater'] = 20; //Ran(1,300);
    json_request.data.advanced['hysteresis_chiller_low'] = 10; //Ran(1,300);
    json_request.data.advanced['hysteresis_chiller_high'] = 20; //Ran(1,300);
    json_request.data.advanced['co2_min_dose_ms'] = 100; //Ran(1,300);
    json_request.data.advanced['intake_sens_min_diff_rh'] = 30;
    json_request.data.advanced['intake_sens_min_diff_temp'] = 3; //Ran(1,300);
    json_request.data.advanced['intake_sens_retry_wait'] = 30; //Ran(1,300);
    json_request.data.advanced['intake_sens_retry_duration'] = 20; //Ran(1,300);
    json_request.data.advanced['rh_min_with_vpd'] = 10; //Ran(1,300);
    json_request.data.advanced['rh_max_with_vpd'] = 80; //Ran(1,300);
    json_request.data.advanced['lights_dim_over_temp_hyst'] = 30;
    json_request.data.advanced['lights_dim_over_temp_delay_sec'] = 30;
    json_request.data.advanced['offset_sp_humidifier'] = 40;
    json_request.data.advanced['dehumid_exhaust_threshold'] = 5;
    json_request.data.advanced['dehumid_exhaust_temp_untercut_max_allowed'] = 5;
    json_request.data.advanced['offset_sp_humidifier_night'] = -80; //Ran(2,30);
    json_request.data.advanced['offset_sp_dehumidifier_night'] = 80; //Ran(2,30);

    json_request.data.system = {};
    json_request.data.system['temp_format'] = 0; //Ran(0,1);
    json_request.data.system['date_format'] = 1; //Ran(0,1);
    json_request.data.system['language'] = 1; //Ran(0,1);
    json_request.data.system['display_timeout'] = 3; //Ran(0,3);
    json_request.data.system['fan_type_select'] = 0; //Ran(0,2);
    json_request.data.system['multi_sensor_handling_rh'] = 0; //Ran(0,2);
    json_request.data.system['multi_sensor_handling_temp'] = 0; //Ran(0,2);
    json_request.data.system['multi_sensor_handling_co2'] = 0; //Ran(0,2);
    json_request.data.system['multi_sensor_handling_pressure'] = 0; //Ran(0,2);
    json_request.data.system['multi_sensor_handling_heating_mat'] = 0; //Ran(0,2);
    json_request.data.system['multi_sensor_handling_leaf'] = 0; //Ran(0,2);
    json_request.data.system['filter_val_sensor_co2'] = 0; //Ran(0,7);
    json_request.data.system['action_transmit_filter_sensor'] = 0; //Ran(0,1);
    json_request.data.system['calib_val_sensor_co2'] = 400; //Ran(0,2000);
    json_request.data.system['action_transmit_calib_filter_sensor_co2'] = 0; //Ran(0,1);
    json_request.data.system['fw_version_number'] = '5055pre0.00.00b';
    json_request.data.system['esp32_firmware_name'] = '5055pre0.00.00b';
    json_request.data.system['pressure_auto_cal'] = Ran(0, 0);

    json_request.data.multi_out = {};
    /* 
    //if GrowBase
    json_request.data.multi_out["out_" + 1 + "_type"] = 3;
    json_request.data.multi_out["out_" + 1 + "_func"] = 1;
    json_request.data.multi_out["out_" + 2 + "_type"] = 3;
    json_request.data.multi_out["out_" + 2 + "_func"] = 0;
    json_request.data.multi_out["out_" + 3 + "_type"] = 3;
    json_request.data.multi_out["out_" + 3 + "_func"] = 2;
    json_request.data.multi_out["out_" + 4 + "_type"] = 1;
    json_request.data.multi_out["out_" + 4 + "_func"] = 4;
    json_request.data.multi_out["out_" + 5 + "_type"] = 1;
    json_request.data.multi_out["out_" + 5 + "_func"] = 4;
    */
    //if GrowBase Pro
    json_request.data.multi_out['out_' + 1 + '_type'] = 0;
    json_request.data.multi_out['out_' + 1 + '_func'] = 1;
    json_request.data.multi_out['out_' + 2 + '_type'] = 0;
    json_request.data.multi_out['out_' + 2 + '_func'] = 0;
    json_request.data.multi_out['out_' + 3 + '_type'] = 0;
    json_request.data.multi_out['out_' + 3 + '_func'] = 2;
    json_request.data.multi_out['out_' + 4 + '_type'] = 0;
    json_request.data.multi_out['out_' + 4 + '_func'] = 4;
    json_request.data.multi_out['out_' + 5 + '_type'] = 2;
    json_request.data.multi_out['out_' + 5 + '_func'] = 4;
    json_request.data.multi_out['memo_names'] = [{ id: 'out_1_memo', name: 'test-memo' }];
    for (var j = 6; j <= 25; j++) {
      json_request.data.multi_out['out_' + j + '_type'] = 1; //Ran(0,3);
      json_request.data.multi_out['out_' + j + '_func'] = 4;
    }

    json_request.data.multi_io = {};
    json_request.data.multi_io.ios = [];
    json_request.data.multi_io.memo_names = [
      { id: 'out_1_memo', name: 'test-memo' },
      { id: 'out_10_memo', name: 'test-memo 10' }
    ];
    for (let i = 0; i < GCSPI_MAX_MULTI_IO_CNT; i++) {
      let io = {};
      io['addr_gcl'] = i < 48 ? 10 : 11;
      io['id_per_addr'] = i < 48 ? i : i - 48;
      io['function'] = i < 48 ? 4 : 2;
      io['hw_type'] = i < 48 ? 20 : 22;
      io['zone'] = 0;
      json_request.data.multi_io.ios.push(io);
    }

    json_request.data.alarms = {};
    json_request.data.alarms['alarm_low_temperature_air_day_setting'] = 100;
    //  = Ran(50,json_request.data.climate.temp_sp_day-20);
    json_request.data.alarms['alarm_over_temperature_air_day_setting'] = 400;
    //  = Ran(json_request.data.climate.temp_sp_day+20,700);
    json_request.data.alarms['alarm_low_temperature_air_night_setting'] = 100;
    //  = Ran(50,json_request.data.climate.temp_sp_night-20);
    json_request.data.alarms['alarm_over_temperature_air_night_setting'] = 400;
    //  = Ran(json_request.data.climate.temp_sp_day+20,500);
    json_request.data.alarms['alarm_low_temperature_air_co2_setting'] = 100;
    //  = Ran(50,json_request.data.co2.temp_sp_co2-20);
    json_request.data.alarms['alarm_over_temperature_air_co2_setting'] = 500;
    //  = Ran(json_request.data.co2.temp_sp_co2+20,500);
    json_request.data.alarms['alarm_low_co2_concentration_setting'] = 400;
    //  = Ran(300,json_request.data.co2.co2_sp-100);
    json_request.data.alarms['alarm_over_co2_concentration_setting'] = 1900;
    //  = Ran(json_request.data.co2.co2_sp+100,1800);
    json_request.data.alarms['alarm_low_rh_day_setting'] = 20;
    //  = Ran(10,json_request.data.climate.humidity_sp_day-5);
    json_request.data.alarms['alarm_over_rh_day_setting'] = 95;
    //  = Ran(json_request.data.climate.humidity_sp_day+5,90);
    json_request.data.alarms['alarm_low_rh_night_setting'] = 20;
    //  = Ran(5,json_request.data.climate.humidity_sp_night-5);
    json_request.data.alarms['alarm_over_rh_night_setting'] = 95;
    //  = Ran(json_request.data.climate.humidity_sp_night+5,95);
    json_request.data.alarms['alarm_low_vpd_setting'] = 20;
    //  = Ran(20,json_request.data.climate.vpd_sp-20);
    json_request.data.alarms['alarm_over_vpd_setting'] = 200;
    //  = Ran(json_request.data.climate.vpd_sp+20,140);
    json_request.data.alarms['alarm_low_vpd_night_setting'] = 20;
    //  = Ran(20,json_request.data.climate.vpd_sp_night-20);
    json_request.data.alarms['alarm_over_vpd_night_setting'] = 200;
    //  = Ran(json_request.data.climate.vpd_sp_night+20,140);
    json_request.data.alarms['alarm_low_temperature_air_day_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_over_temperature_air_day_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_low_temperature_air_night_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_over_temperature_air_night_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_low_temperature_air_co2_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_over_temperature_air_co2_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_low_co2_concentration_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_over_co2_concentration_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_low_rh_day_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_over_rh_day_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_low_rh_night_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_over_rh_night_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_low_vpd_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_over_vpd_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_low_vpd_night_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_over_vpd_night_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_sensor_error_rht_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_sensor_error_co2_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_sensor_error_pressure_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_sensor_error_heating_mat_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_sensor_error_leaf_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_error_power_extender_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['alarm_sensor_error_intake_enable'] = 0; //Ran(0,1);
    json_request.data.alarms['expected_power_extender_out_cnt'] = 0; //Ran(1,20);

    json_request.data.schedule_meta = {};
    json_request.data.schedule_meta['enable_scheduling'] = true;
    json_request.data.schedule_meta['name_short'] = 'Schedule simu';
    json_request.data.schedule_meta['active_phase_cnt'] = 4;
    json_request.data.schedule_meta['len_phase_1_days'] = 5;
    json_request.data.schedule_meta['len_phase_2_days'] = 2;
    json_request.data.schedule_meta['len_phase_3_days'] = 10;
    json_request.data.schedule_meta['len_phase_4_days'] = 56;
    json_request.data.schedule_meta['len_phase_5_days'] = -1;
    json_request.data.schedule_meta['len_phase_6_days'] = -1;
    json_request.data.schedule_meta['len_phase_7_days'] = -1;
    json_request.data.schedule_meta['len_phase_8_days'] = -1;
    json_request.data.schedule_meta['len_phase_9_days'] = -1;
    json_request.data.schedule_meta['len_phase_10_days'] = -1;
    json_request.data.schedule_meta['enable_smooth_change'] = 1;
    json_request.data.schedule_meta['enable_humidity'] = 1;
    json_request.data.schedule_meta['enable_temper_main'] = -1;
    json_request.data.schedule_meta['enable_vpd'] = 0;
    json_request.data.schedule_meta['enable_heater'] = 0;
    json_request.data.schedule_meta['enable_heating_mat'] = 0;
    json_request.data.schedule_meta['enable_co2_concentr'] = 0;
    json_request.data.schedule_meta['enable_co2_temper'] = 0;
    json_request.data.schedule_meta['enable_day_night'] = 0;
    json_request.data.schedule_meta['enable_dim_ch_1'] = 0;
    json_request.data.schedule_meta['enable_dim_ch_2'] = 0;
    json_request.data.schedule_meta['enable_dim_ch_3'] = 0;
    json_request.data.schedule_meta['enable_dim_ch_4'] = 0;
    json_request.data.schedule_meta['enable_interval_1'] = 0;
    json_request.data.schedule_meta['enable_interval_2'] = 0;
    json_request.data.schedule_meta['enable_event_1'] = 0;
    json_request.data.schedule_meta['enable_event_2'] = 0;
    json_request.data.schedule_meta['enable_event_3'] = 0;
    json_request.data.schedule_meta['enable_event_4'] = 0;
    json_request.data.schedule_meta['enable_event_5'] = 0;
    json_request.data.schedule_meta['enable_event_6'] = 0;
    json_request.data.schedule_meta['enable_event_7'] = 0;
    json_request.data.schedule_meta['enable_event_8'] = 0;

    const phase_1 = MakePhase({
      name_phase_short: 'Propagation',
      phase_id: 1,
      rh_sp_day: 769,
      rh_sp_night: 760,
      temp_main_sp_day: 280,
      temp_main_sp_night: 200,
      vpd_sp_day: 80
    });
    const phase_2 = MakePhase({
      name_phase_short: 'Vegi 1',
      phase_id: 2,
      rh_sp_day: 759,
      rh_sp_night: 710,
      temp_main_sp_day: 200,
      temp_main_sp_night: 220,
      vpd_sp_day: 70
    });

    const phase_3 = MakePhase({
      name_phase_short: 'Flower 1',
      phase_id: 3,
      rh_sp_day: 769,
      rh_sp_night: 760,
      temp_main_sp_day: 280,
      temp_main_sp_night: 200,
      vpd_sp_day: 80
    });
    const phase_4 = MakePhase({
      name_phase_short: 'Your Memo',
      phase_id: 4,
      rh_sp_day: 759,
      rh_sp_night: 710,
      temp_main_sp_day: 200,
      temp_main_sp_night: 220,
      vpd_sp_day: 70
    });
    json_request.data.schedule_values = [phase_1, phase_2, phase_3, phase_4];

    json_request.data['nutrition'] = {};
    json_request.data.nutrition['ec_setpoint'] = 220;
    json_request.data.nutrition['ph_setpoint'] = 420;
    json_request.data.nutrition['temp_setpoint'] = 210;
    json_request.data.nutrition['pump_cnt_used'] = 4;
    json_request.data.nutrition['pumps'] = [];
    const memo_names = ['Comp A', 'Comp B', 'Additive 1', 'Additive 2'];
    for (let i = 0; i < 4; i++) {
      if (i < 2)
        json_request.data.nutrition.pumps.push({
          type: 0,
          unit: 1,
          value: 25,
          name: memo_names[i]
        });
      else
        json_request.data.nutrition.pumps.push({
          type: 2,
          unit: 0,
          value: 20,
          name: memo_names[i]
        });
    }

    json_request.data['irrigation'] = {};
    json_request.data.irrigation['irrigation_p0_dry_back_abs'] = 5;
    json_request.data.irrigation['irrigation_p0_phase_timeout_minutes'] = 90;
    json_request.data.irrigation['irrigation_p0_enable'] = 1;
    json_request.data.irrigation['irrigation_p1_shot_size_vwc_rel'] = 65;
    json_request.data.irrigation['irrigation_p1_target_vwc_abs'] = 64;
    json_request.data.irrigation['irrigation_p1_dry_back_rel'] = 3;
    json_request.data.irrigation['irrigation_p1_wait_after_shot_minutes'] = 2;
    json_request.data.irrigation['irrigation_p1_phase_timeout_minute'] = 90;
    json_request.data.irrigation['irrigation_p1_shot_duration_timeout_minutes'] = 5;
    json_request.data.irrigation['irrigation_p1_memo_max_vwc'] = 1;
    json_request.data.irrigation['irrigation_p2_strategy'] = 0;
    json_request.data.irrigation['irrigation_p2_drain_sp'] = 20;
    json_request.data.irrigation['irrigation_p2_target_vwc_abs'] = 65;
    json_request.data.irrigation['irrigation_p2_dry_back_abs'] = 5;
    json_request.data.irrigation['irrigation_p2_shot_duration_timeout_minutes'] = 5;
    json_request.data.irrigation['irrigation_p2_wait_after_shot_s'] = 5;
    json_request.data.irrigation['irrigation_p2_end_before_sunset'] = '02:30:00';
    json_request.data.irrigation['irrigation_p3_min_vwc_abs'] = 20;
    json_request.data.irrigation['irrigation_p3_shot_size_rel'] = 3;
    json_request.data.irrigation['irrigation_p3_min_wait_after_shot'] = 120;
  }
  return json_request;
}

export function GenerateSimulationHistory(length, res, block, max) {
  res *= 1000; // convert to milli_seconds.
  var data = {
    time: [],
    humidity: [],
    temp: [],
    co2: [],
    vpd: [],
    temp_leaf_current: [],
    vol_water_content: [],
    ec_root_zone: []
  };

  // init start time
  const now = new Date();
  let milli_seconds = now.getTime();
  milli_seconds -= length * res * (max - block);

  const bounds = {
    humidity: [590, 610],
    temp: [250, 300],
    co2: [1400, 1500],
    vpd: [70, 90],
    temp_leaf_current: [240, 270],
    vol_water_content: [0, 100],
    ec_root_zone: [1000, 3000]
  };
  let last_vals = {
    humidity: Ran(bounds['humidity'][0], bounds['humidity'][1]),
    temp: Ran(bounds['temp'][0], bounds['temp'][1]),
    co2: Ran(bounds['co2'][0], bounds['co2'][1]),
    vpd: Ran(bounds['vpd'][0], bounds['vpd'][1]),
    temp_leaf_current: Ran(bounds['temp_leaf_current'][0], bounds['temp_leaf_current'][1]),
    vol_water_content: Ran(bounds['vol_water_content'][0], bounds['vol_water_content'][1]),
    ec_root_zone: Ran(bounds['ec_root_zone'][0], bounds['ec_root_zone'][1])
  };

  // Add values matching `length`:
  for (var i = 0; i < length; i++) {
    for (const [key, value] of Object.entries(last_vals)) {
      const lower = 5 - (value - bounds[key][0] <= 5) ? value - bounds[key][0] : 0;
      const upper = 5 - (bounds[key][1] - value <= 5) ? bounds[key][1] - value : 0;
      last_vals[key] = value + Ran(-lower, upper); // artificial deviation of +/- 5
      data[key].push(last_vals[key]);
    }
    data['time'].push(milli_seconds);
    milli_seconds += res;
  }
  return data;
}

export function MakePhase(init) {
  return {
    name_phase_short: init.name_phase_short || '',
    name_phase_full: '',
    phase_id: init.phase_id,
    rh_sp_day: init.rh_sp_day || -1,
    rh_sp_night: init.rh_sp_night || -1,
    temp_main_sp_day: init.temp_main_sp_day || -1,
    temp_main_sp_night: init.temp_main_sp_night || -1,
    vpd_sp_day: init.vpd_sp_day || -1,
    vpd_sp_night: init.vpd_sp_day || -1,
    vpd_control_enable: init.vpd_control_enable || 0,
    temp_heater_sp_day: init.temp_heater_sp_day || -1,
    temp_heater_sp_night: init.temp_heater_sp_night || -1,
    temp_heating_mat_day_rel: init.temp_heating_mat_day_rel || -1,
    temp_heating_mat_night_rel: init.temp_heating_mat_night_rel || -1,
    temp_heating_mat_day_abs: init.temp_heating_mat_day_abs || -1,
    temp_heating_mat_night_abs: init.temp_heating_mat_night_abs || -1,
    heating_mat_day_rel0_abs1: init.heating_mat_day_rel0_abs1 || 0,
    heating_mat_night_rel0_abs1: init.heating_mat_night_rel0_abs1 || 1,
    co2_control_on_off: init.co2_control_on_off || 0,
    co2_concentr_sp: init.co2_concentr_sp || -1,
    temp_main_sp_co2: init.temp_main_sp_co2 || -1,
    temp_heater_sp_co2: init.temp_heater_sp_co2 || -1,
    lights_max_ch_1: init.lights_max_ch_1 || -1,
    lights_max_ch_2: init.lights_max_ch_2 || -1,
    lights_max_ch_3: init.lights_max_ch_3 || -1,
    lights_max_ch_4: init.lights_max_ch_4 || -1,
    time_day_start: init.time_day_start || '01:00:00',
    time_day_end: init.time_day_end || '01:00:00',
    time_interval_1_on_day: init.time_interval_1_on_day || '00:00:00',
    time_interval_1_off_day: init.time_interval_1_off_day || '00:00:00',
    time_interval_1_on_night: init.time_interval_1_on_night || '00:00:00',
    time_interval_1_off_night: init.time_interval_1_off_night || '00:00:00',
    time_interval_2_on_day: init.time_interval_2_on_day || '00:00:00',
    time_interval_2_off_day: init.time_interval_2_off_day || '00:00:00',
    time_interval_2_on_night: init.time_interval_2_on_night || '00:00:00',
    time_interval_2_off_night: init.time_interval_2_off_night || '00:00:00',
    time_event1_start: init.time_event1_start || '00:00:00',
    time_event1_duration: init.time_event1_duration || '00:00:00',
    event_1_exec_gap: init.event_1_exec_gap || 20,
    time_event2_start: init.time_event2_start || '00:00:00',
    time_event2_duration: init.time_event2_duration || '00:00:00',
    event_2_exec_gap: init.event_2_exec_gap || 20,
    time_event3_start: init.time_event3_start || '00:00:00',
    time_event3_duration: init.time_event3_duration || '00:00:00',
    event_3_exec_gap: init.event_3_exec_gap || 20,
    time_event4_start: init.time_event4_start || '00:00:00',
    time_event4_duration: init.time_event4_duration || '00:00:00',
    event_4_exec_gap: init.event_4_exec_gap || 20,
    time_event5_start: init.time_event5_start || '00:00:00',
    time_event5_duration: init.time_event5_duration || '00:00:00',
    event_5_exec_gap: init.event_5_exec_gap || 20,
    time_event6_start: init.time_event6_start || '00:00:00',
    time_event6_duration: init.time_event6_duration || '00:00:00',
    event_6_exec_gap: init.event_6_exec_gap || 20,
    time_event7_start: init.time_event7_start || '00:00:00',
    time_event7_duration: init.time_event7_duration || '00:00:00',
    event_7_exec_gap: init.event_7_exec_gap || 20,
    time_event8_start: init.time_event8_start || '00:00:00',
    time_event8_duration: init.time_event8_duration || '00:00:00',
    event_8_exec_gap: init.event_8_exec_gap || 20
  };
}
