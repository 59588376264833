import React from 'react';
import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, useParams, Route } from 'react-router-dom';
import { useConfigObject } from './contentConfig.js';
import { CircularIndeterminate } from '../../FormWidgets/Others';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {
  controller_project_exclude_features as cpef,
  controller_type_exclude_features as ctef
} from '../../Constants/ControllerFeatures.js';

function AllSettingsArrived(controller, parent) {
  return Object.keys(parent?.settings ?? {}).length >= (controller.type === 6000 ? 10 : 12);
}

function MissingCategory(tab_id, parent) {
  for (const id of ['nutrition', 'irrigation']) {
    if (tab_id === id && !(id in parent.settings)) return true;
  }
  return false;
}

export function SettingsTabs(props) {
  let { tab_id = 'info' } = useParams();
  let { t } = useTranslation();
  const cont_type = props.controller ? props.controller.type : '';
  const project_id = props.controller ? props.controller.project_id : '';

  const tabs = useConfigObject(props.controller, props.parent).filter(
    (tab) =>
      (!(cont_type in ctef) || !ctef[cont_type]['tabs'].includes(tab.id)) &&
      (!(project_id in cpef) || !cpef[project_id]['tabs'].includes(tab.id))
  );

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper
    }
  }));
  const classes = useStyles();
  const tab_index = tabs.findIndex((x) => x.id === tab_id);

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Box display="flex" justifyContent="center" width="100%">
            <Tabs
              value={tab_index}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="controller settings tabs"
            >
              {tabs.map((tab, index) => (
                <Tab
                  label={t(tab.id)}
                  component={Link}
                  to={`${props.url}/${tab.id}`}
                  key={`controller-tab-${index}`}
                  id={`controller-tab-${index}`}
                  aria-controls={`controller-tabpanel-${index}`}
                />
              ))}
            </Tabs>
          </Box>
        </AppBar>
      </div>

      {AllSettingsArrived(props.controller, props.parent) ? (
        tabs?.map((item) => (
          <Route path={`${props.path}/:tab_id?`}>
            <Container key={'tab_' + item.id}>
              <SettingsTabPanel id={item.id} parent={props.parent}>
                {item.content}
              </SettingsTabPanel>
            </Container>
          </Route>
        ))
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3em' }}>
          <CircularIndeterminate />
        </div>
      )}
    </>
  );
}

function SettingsTabPanel({ id, parent, children, ...other }) {
  let { tab_id } = useParams();
  if (id === 'info' && tab_id === undefined) tab_id = id;

  return (
    <div
      role="tabpanel"
      hidden={tab_id !== id}
      id={`wrapped-tabpanel-${id}`}
      aria-labelledby={`wrapped-tab-${id}`}
      {...other}
    >
      {tab_id === id && !MissingCategory(id, parent) && (
        <Box style={{ marginTop: 50 }}>{children}</Box>
      )}
      {tab_id === id && MissingCategory(id, parent) && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3em' }}>
          Please Update Controller to see these Settings...
        </div>
      )}
    </div>
  );
}
