import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export function EditableLabelContent({ label, value, prefix, postfix, no_label = false }) {
  prefix = prefix !== undefined ? prefix : '';
  postfix = postfix !== undefined ? postfix : '';
  return (
    <React.Fragment>
      {!no_label && (
        <React.Fragment>
          {label}
          <br />
        </React.Fragment>
      )}
      {value !== -1 && value !== '' ? prefix + value + postfix : <br />}
    </React.Fragment>
  );
}

export function EditableLabel({
  label,
  id,
  value,
  handleInput,
  prefix,
  postfix,
  no_label = false
}) {
  let { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const value_hint = value !== -1 && value !== '' ? value : t('custom_memo_name');

  return (
    <React.Fragment>
      <Typography gutterBottom>
        <EditableLabelContent
          label={label}
          value={value_hint}
          prefix={prefix}
          postfix={postfix}
          no_label={no_label}
        />
        <EditIcon
          style={{ cursor: 'pointer', marginLeft: 1, marginBottom: -1 }}
          onClick={openDialog}
        />
      </Typography>

      <MemoDialog
        open={open}
        handleClose={closeDialog}
        name={id}
        curMemo={value !== -1 && value !== '' ? value : ''}
        handleInput={handleInput}
      />
    </React.Fragment>
  );
}

function ValidateMemoName(memo_name) {
  if (memo_name.length === 0) return '';
  if (memo_name.length > 20) return 'memo_name_max_chars_exeeded';
  if (!memo_name.match(/^[-\-+\-&\-/\-0-9\-a-z\-A-Z(). ]+$/g))
    return 'memo_name_invalid_characters';
  return '';
}

function MemoDialog({ open, handleClose, name, curMemo, handleInput }) {
  let { t } = useTranslation();
  const [memo, setMemo] = React.useState({ value: curMemo });
  const [error, setError] = React.useState('');

  const changeMemo = (event) => {
    const new_error = ValidateMemoName(memo.value);
    if (new_error === '') {
      handleInput(name, event, memo.value);
      setError('');
      handleClose();
    } else setError(t(new_error));
  };

  const deleteMemo = (event) => {
    setMemo({ value: '' });
    handleInput(name, event, -1);
    setError('');
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('change_memo')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="serial"
          label="Memo"
          type="text"
          fullWidth
          placeholder={t('custom_memo_name')}
          value={memo.value}
          onChange={(event) => setMemo({ ...memo, value: event.target.value })}
        />
        {error}
      </DialogContent>
      <DialogActions>
        <Button onClick={changeMemo} color="primary">
          {t('set')}
        </Button>
        <Button onClick={deleteMemo} color="primary">
          {t('delete')}
        </Button>
        <Button onClick={handleClose} color="primary">
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
