import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { MultiIOSelect, MultiOutSelect } from '../FormWidgets/Select.js';

import { useTranslation } from 'react-i18next';
import { LoadSettingsDialog } from '../Utils/load_settings';

import { MultiOutHeadingEditable } from '../FormWidgets/MultiOutLabel';
import { SettingGroup, ValueBox } from '../FormWidgets/SettingGroup';

import { SettingActions } from '../components/index.js';

import { makeMultiOutObject } from '../Utils/utils.js';

import _ from 'lodash';

import { settingControls, useSavedSettings, writeTempSettings } from './settingFunctions';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.innerContainer2.background
  }
}));

export default function MultiOutSettings({ controller, parent, ...other }) {
  let { t } = useTranslation();
  let sid = controller.type === 5155 && parent.settings.multi_io != null ? 'multi_io' : 'multi_out'; // tab id is "outputs"

  let multi_outs = makeMultiOutObject(controller, parent);
  const [values, setValues] = useState(
    controller.type >= 6000 || (controller.type === 5155 && parent.settings.multi_io != null)
      ? multi_outs
      : parent.settings[sid]
  );
  const controls = settingControls(controller, parent, sid, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings(
    parent,
    controls,
    sid
  );

  const transform_sent_settings = (settings) => {
    if (controller.type === 5155 && parent.settings.multi_io != null) {
      let updated_settings = { ios: [], memo_names: [] };
      for (let address in settings) {
        for (let [_, value] of Object.entries(settings[address])) {
          updated_settings['ios'].push({
            addr_gcl: value.address,
            id_per_addr: value.id - 1,
            function: value.func,
            hw_type: value.type << 4,
            zone: value.zone
          });
          updated_settings['memo_names'].push({
            id: 'out_' + (value.index + 1) + '_memo',
            name: value.memo ?? ''
          });
        }
      }
      return updated_settings;
    }
    // convert only memo names for all other controllers
    else {
      let updated_settings = settings;
      for (let i = 1; i <= parent.settings.multi_out_values.out_count ?? 0; i++) {
        const id = 'out_' + i + '_memo';
        if (id in settings)
          updated_settings['memo_names'].push({
            id: 'out_' + i + '_memo',
            name: settings[id]
          });
      }
      return updated_settings;
    }
  };

  const saveSelectIndustrial = (slave, port_id, event, value) => {
    let v = event.target.value;
    let vv = { ...values };
    vv[slave][port_id].func = v;
    setValues(vv);
    controls.saveTempSettings(`multi_out_${slave}_${port_id}_func`, v);
  };

  const saveSelectGrowbase = (index, event, value) => {
    let id = `out_${index + 1}_func`;
    let v = event.target.value;
    setValues({ ...values, [id]: v });
    controls.saveTempSettings(id, v);
  };

  const saveMemoIndustrial = (slave, port_id, event, value) => {
    //let id = Number (port_id.match (/out_([0-9]*)_memo/)[1]);
    let vv = { ...values };
    vv[slave][port_id].memo = value;
    setValues(vv);
    controls.saveTempSettings(`multi_out_${slave}_${port_id}_memo`, value);
  };

  const saveMemoGrowbase = (id, event, value) => {
    setValues({ ...values, [id]: value });
    controls.saveTempSettings(id, value);
  };

  if (controller.type >= 6000 || (controller.type === 5155 && parent.settings.multi_io != null)) {
    controls.restoreTempSettings = () => {
      let _values = { ...values };
      for (const [k, v] of Object.entries(parent.temp_settings[sid])) {
        for (let field of ['func', 'memo']) {
          let match = k.match(new RegExp(`multi_out_([0-9]+)_([0-9]+)_${field}`));
          if (match) {
            let slave = match[1];
            let port = match[2];
            _values[slave][port][field] = v;
          }
        }
        setValues(_values);
      }
    };

    controls.resetSettings = () => {
      setValues(_.cloneDeep(multi_outs));
      delete parent.temp_settings[sid];
      writeTempSettings(parent);
    };
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions
        onReset={controls.resetSettings}
        onSave={() => {
          // For indistrial lite, data needs to be transformed inside of gui as
          // wel, for all others data needs to be transformed only before
          // sending.
          controls.saveSettings(
            transform_sent_settings,
            controller.type === 5155 && parent.settings.multi_io != null
          );
        }}
      />

      <SettingGroup
        label={
          controller.type === 6000 || (controller.type === 5155 && parent.settings.multi_io != null)
            ? t('multifunctional_ios_heading')
            : t('multifunctional_outputs_heading')
        }
      >
        {controller.type === 6000 ||
        (controller.type === 5155 && parent.settings.multi_io != null) ? (
          <>
            {Object.keys(values).map((slave) => (
              <div
                className={classes.box}
                style={{ padding: '1em', width: '100%', marginBottom: '0.5em' }}
              >
                <span style={{ fontSize: '1.5em' }}>
                  {' '}
                  Slave: <span style={{ fontFamily: 'monospace' }}>0x{slave.toString(16)}</span>
                </span>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {Object.values(values[slave]).map(
                    (v) =>
                      v.type !== 0 && (
                        <ValueBox>
                          <MultiOutHeadingEditable
                            controller={controller}
                            parent={parent}
                            index={Number(v.id)}
                            memo={v.memo ?? ''}
                            handleInput={(id, event, value) =>
                              saveMemoIndustrial(slave, v.id, event, value)
                            }
                            multi_out={v}
                          />
                          {controller.type === 5155 && parent.settings.multi_io != null ? (
                            <MultiIOSelect
                              value={v.func ?? ''}
                              type={v.type ?? ''}
                              onChange={(event, value) =>
                                saveSelectIndustrial(slave, v.id, event, value)
                              }
                            />
                          ) : (
                            <MultiOutSelect
                              value={v.func ?? ''}
                              type={v.type ?? ''}
                              name={Number(v.id)}
                              onChange={(event, value) =>
                                saveSelectIndustrial(slave, v.id, event, value)
                              }
                              controller={controller}
                              parent={parent}
                              port={v}
                            />
                          )}
                        </ValueBox>
                      )
                  )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            {multi_outs.map((multi_out_index, i) => (
              <ValueBox>
                <MultiOutHeadingEditable
                  controller={controller}
                  parent={parent}
                  index={i + 1}
                  memo={values[`out_${i + 1}_memo`] ?? ''}
                  handleInput={saveMemoGrowbase}
                />
                <MultiOutSelect
                  value={values[`out_${i + 1}_func`] ?? ''}
                  type={values[`out_${i + 1}_type`] ?? ''}
                  name={multi_out_index[0]}
                  controller={controller}
                  onChange={(event, value) => saveSelectGrowbase(i, event, value)}
                />
              </ValueBox>
            ))}
          </>
        )}
      </SettingGroup>

      <SettingActions
        onReset={controls.resetSettings}
        onSave={() => {
          // For indistrial lite, data needs to be transformed inside of gui as
          // wel, for all others data needs to be transformed only before
          // sending.
          controls.saveSettings(
            transform_sent_settings,
            controller.type === 5155 && parent.settings.multi_io != null
          );
        }}
      />
    </React.Fragment>
  );
}
