import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import Button from '@material-ui/core/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { useTranslation } from 'react-i18next';
import momentWithLocale, { diff } from '../local_moment';

import Typography from '@material-ui/core/Typography';
import { GraphSlider } from '../FormWidgets/MySlider';
import {
  HistoryResSelect,
  DecimalSelect,
  DelimiterSelect,
  TimeFormatSelect
} from '../FormWidgets/Select';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { inital_data, resolution_mapping } from './Graph/graph_config';
import { HISTORY_VALUES } from '../Constants/constants';
import { ParseFloat, PrintDate } from '../Utils/utils';

import getStateManager from '../StateManager.js';
import { useSnapshot, proxy, subscribe } from 'valtio';
import { subscribeKey } from 'valtio/utils';
import { useAddress } from '../Hooks/addressHook';
import { useSnackbar } from 'notistack';

import _ from 'lodash';

function LineChart({ data, options, plugins }) {
  return <Line data={data} options={options} plugins={plugins} />;
}
const MemorizedChart = React.memo(LineChart);

function CalcLabelFormat(labels) {
  var show_months = 0;
  if (labels.length > 1) {
    for (let i = 0; i < labels.length - 1 && i < 10; i++) {
      const cur = momentWithLocale(labels[i]);
      const next = momentWithLocale(labels[i + i]);
      show_months += diff(cur, next) > 10 ? 1 : -1;
    }
  }
  return show_months > 0 ? 'D MMM HH:mm' : 'HH:mm:ss';
}

function SliceData(labels, datasets, start, end) {
  // slice labels and data.
  for (let i = 0; i < datasets.length; i++)
    datasets[i].data = datasets[i].data.splice(start, end - start);
  labels = labels.splice(start, end - start);

  // Convert timestamp.
  const first_ts = momentWithLocale(labels[0]);
  const last_ts = momentWithLocale(labels[labels.length - 1]);

  // Get formats for timestamps and for view-display at the top of the page.
  const format = CalcLabelFormat(labels);
  const view_format = first_ts.format('L') !== last_ts.format('L') ? 'lll' : 'HH:mm:ss';

  // Set format for all timestamps
  for (let i = 0; i < labels.length; i++) {
    labels[i] = PrintDate(labels[i], format);
  }

  // Get what to show infront of range (today, date, nothing)
  let what = '';
  if (first_ts.format('L') === last_ts.format('L'))
    what =
      momentWithLocale().format('L') === first_ts.format('L')
        ? 'history_today'
        : first_ts.utc().format('ll');

  return {
    graph: { labels: labels, datasets: datasets },
    vp: { what: what, from: PrintDate(first_ts, view_format), to: PrintDate(last_ts, view_format) }
  };
}

function ReduceData(graph, threshold, goal) {
  const cur_length = graph.datasets[0].data.length;
  const labels = graph.labels;
  const datasets = graph.datasets;

  // If threshold not reached, don't do anything
  if (cur_length < threshold) return graph;

  // Create new empty arrays for labels and data.
  let new_labels = [];
  let new_data = [];
  for (var i = 0; i < datasets.length; i++) new_data.push([]);
  const diff = Math.floor(cur_length / goal);

  // Fill new arrays with reduced data.
  for (let i = 0; i < cur_length; i += diff) {
    // handle labels
    const selection = labels.slice(i, i + diff);
    new_labels.push(selection[Math.floor(selection.length / 2)]);
    // handle datasets
    for (let j = 0; j < datasets.length; j++) {
      const selection = datasets[j].data.slice(i, i + diff);
      new_data[j].push(selection[Math.floor(selection.length / 2)]);
    }
  }
  // Update datasets
  for (let i = 0; i < datasets.length; i++) graph.datasets[i].data = new_data[i];
  graph.labels = new_labels;
  return graph;
}

// Valtio's `subscribe` does not work with `useState`
// we have to use also a valtio store for this
// I opened an issue for this https://github.com/pmndrs/valtio/discussions/857
const store = proxy({
  data: _.cloneDeep(inital_data),
  range: { start: 0, end: -1 },
  id: ''
});

export function Graph({ controller, parent, ...other }) {
  let { t } = useTranslation();
  let { id } = useParams();
  let { enqueueSnackbar } = useSnackbar();
  const address = useAddress();

  const stateManager = getStateManager();

  const snap = useSnapshot(store);
  const data = snap.data;

  // initialize displayed selection
  const [show_data, setShowData] = useState(_.cloneDeep(inital_data));

  // View range.
  const [displayVP, setDisplayVP] = useState({ what: 'Today', from: '', to: '' }); // displays visible range.
  const [range_slider_value, setRangeSliderValue] = React.useState([0, 0]); // current slider value.
  const updateRangeSliderValue = (event, new_value) => {
    setRangeSliderValue(new_value);
  };

  const updateRange = (value) => {
    if (value[0] < value[1]) {
      store.range.start = value[0];
      store.range.end = value[1] === store.data.datasets[0].data.length ? -1 : value[1];
    }
  };

  useEffect(() => {
    store.id = parent.id;
  }, []);

  // Update full-data, if histroy was changed
  useEffect(() => {
    // Reacts to updated full-data and setting (vp-range) and parses the values to show.
    function reduce() {
      const datasets = _.cloneDeep(store.data.datasets);
      const labels = _.cloneDeep(store.data.labels);
      const end = store.range.end === -1 ? store.data.datasets[0].data.length : store.range.end;
      // Slice data to selected view
      const sliced_data = SliceData(labels, datasets, store.range.start, end);
      // reduce data if certain threshold is reached.
      const reduced_data = ReduceData(sliced_data.graph, 502, 400);
      setShowData(reduced_data);
      setDisplayVP(sliced_data.vp);
    }

    function update() {
      if (!_.isEmpty(parent.history.data)) {
        if (
          parent.history.data.time.length > 0 &&
          parent.history.data.progress === 100 &&
          store.data.labels.length > 0 &&
          store.data.labels.at(-1) === parent.history.data.time.at(-1)
        ) {
          console.log('[hist] detected possible double subscription: ', parent.id);
        } else if (
          parent.history.data.time.length > 0 &&
          parent.history.data.progress === 100 &&
          (store.data.labels.length === 0 ||
            store.data.labels.at(-1) !== parent.history.data.time.at(-1))
        ) {
          console.log('[hist] Updating graph for controller: ', parent.id, ' (update)');
          // Check whether to add ALL datasets and labels or only last.
          const all_values = store.data.labels.length < 2; // if almost zero data, add ALL values
          // Data and hidden
          for (const ds of store.data.datasets) {
            if (all_values) ds.data = _.cloneDeep(parent.history.data[ds.label]);
            else
              ds.data.push(parent.history.data[ds.label][parent.history.data[ds.label].length - 1]);
            ds.hidden = parent.history.data.hidden[ds.label];
          }
          // Update labels
          if (all_values) store.data.labels = _.cloneDeep(parent.history.data.time);
          else
            store.data.labels.push(parent.history.data.time[parent.history.data.time.length - 1]);

          let datasets = store.data.datasets.map((d) => d.data.length).filter((d) => d > 0);
          if (datasets.length > 0) {
            setRangeSliderValue(
              store.range.end === -1
                ? [store.range.start, datasets[0]]
                : [store.range.start, store.range.end]
            );
          }
        } else {
          console.log('[hist] Updating graph for controller: ', parent.id, ' (reset)');
          store.data = _.cloneDeep(inital_data);
          setRangeSliderValue([0, 0]);
          store.range.start = 0;
          store.range.end = -1;
        }

        reduce();
      }
    }

    // Main update subscriptions
    const unsubscripeHistory = subscribe(parent.history, update);
    const unsubscribeRange = subscribe(store.range, reduce);
    const unsubscribeData = subscribe(store.data, reduce);

    // Subscribtion on controller-id, to make sure data is reset
    function reset(new_id) {
      console.log('[hist] cont id changed: ', new_id, '. resetting graph-data.');
      store.data = _.cloneDeep(inital_data);
      setRangeSliderValue([0, 0]);
      store.range.start = 0;
      store.range.end = -1;
    }
    subscribeKey(store, 'id', (v) => reset(v));

    update();

    return () => {
      if (unsubscripeHistory) unsubscripeHistory();
      if (unsubscribeRange) unsubscribeRange();
      if (unsubscribeData) unsubscribeData();
    };
  }, []);

  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');
    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.padding = 0;
      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item) => {
        if (
          ([5055, 5155, 6000].includes(chart.options.contType) ||
            (item.text !== 'vpd' && item.text !== 'temp_leaf_current')) &&
          ([5155, 6000].includes(chart.options.contType) ||
            (item.text !== 'vol_water_content' && item.text !== 'ec_root_zone'))
        ) {
          const li = document.createElement('li');
          li.style.alignItems = 'center';
          li.style.cursor = 'pointer';
          li.style.display = 'flex';
          li.style.flexDirection = 'row';
          li.style.marginLeft = '10px';
          li.style.marginBottom = '3px';

          li.onclick = () => {
            // Change hidden
            parent.history.data.hidden[item.text] = !parent.history.data.hidden[item.text];
            var Hidden = {
              internal_id: controller.id,
              field: item.text,
              hidden: parent.history.data.hidden[item.text]
            };
            fetch(address.api_url('/api/set_graph_hidden'), {
              method: 'POST',
              body: JSON.stringify(Hidden)
            }).then((response) => {
              if (response.ok) {
                console.log('[graph] hidden_set_success');
              } else {
                enqueueSnackbar(t('hidden_set_error'), { variant: 'error' });
              }
            });
          };

          // Color box
          const boxSpan = document.createElement('span');
          //boxSpan.style.background = item.fillStyle;
          boxSpan.style.color = item.strokeStyle;
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderWidth = item.lineWidth + 'px';
          boxSpan.style.display = 'inline-block';
          boxSpan.style.height = '20px';
          boxSpan.style.marginRight = '10px';
          boxSpan.style.width = '20px';
          boxSpan.innerHTML = ReactDOMServer.renderToString(
            item.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />
          );

          // Text
          const textContainer = document.createElement('p');
          textContainer.style.color = item.fillStyle;
          textContainer.style.margin = 0;
          textContainer.style.padding = 0;
          textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

          const text = document.createTextNode(t(item.text));
          textContainer.appendChild(text);

          li.appendChild(boxSpan);
          li.appendChild(textContainer);
          ul.appendChild(li);
        }
      });
    }
  };

  const [options] = useState({
    contType: parent.type,
    responsive: true,
    elements: {
      point: {
        radius: 1
      }
    },
    scales: {
      low: { position: 'left', display: 'auto', type: 'linear' },
      high: { position: 'right', display: 'auto', type: 'linear' },
      xAxes: {
        ticks: {
          // autoSkip: false,
          maxRotation: 90,
          minRotation: 90,
          padding: 4,
          font: {
            size: 10
          }
        }
      }
    },
    plugins: {
      htmlLegend: { containerID: 'legend-container' },

      legend: { display: false },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            let blocks = [];
            if (dataset.label) blocks.push(t(dataset.label) + ':');
            blocks.push(tooltipItem.formattedValue);
            if (dataset.unitPrefix) blocks.push(dataset.unitPrefix);
            return blocks.join(' ');
          }
        }
      }
    }
  });

  const updateResolution = (e, value) => {
    // Update global state
    const curControllerHistory = parent.history.data;
    curControllerHistory.resolution = value;
    for (const type of [
      'time',
      'humidity',
      'temp',
      'co2',
      'vpd',
      'temp_leaf_current',
      'vol_water_content',
      'ec_root_zone'
    ]) {
      curControllerHistory[type] = [];
      curControllerHistory.progress = 0;
      curControllerHistory.last_ts = 0;
      curControllerHistory.pending_data = [];

      parent.history.data = curControllerHistory;

      stateManager.wsManager.sendWSMsg(controller, resolution_mapping[value].req);
    }
  };

  const [open_export_dialog, setOpenExportDialog] = React.useState(false);
  const openExportDialog = () => {
    setOpenExportDialog(true);
  };
  const closeExportDialog = () => {
    setOpenExportDialog(false);
  };

  return (
    <React.Fragment>
      <ExportDialog
        open={open_export_dialog}
        handleClose={closeExportDialog}
        controllerHistory={parent.history.data}
        id={id}
      />
      <Typography variant="subtitle2" align="center" style={{ marginTop: `1rem` }}>
        {parent.history.data.progress === 100 ? (
          <i>
            {t('history_showing')} {t(displayVP.what)} {displayVP.from} {t('history_to')}{' '}
            {displayVP.to}
          </i>
        ) : (
          <i>loading...</i>
        )}
      </Typography>
      <HistoryResSelect
        value={parent.history.data.resolution}
        onChange={(event, value) => updateResolution(event, event.target.value)}
      />
      <Button onClick={openExportDialog}>{t('export')} (CSV)</Button>

      {parent.history.data.progress === 100 ? (
        <MemorizedChart data={show_data} options={options} plugins={[htmlLegendPlugin]} />
      ) : (
        <Box sx={{ with: '80%', margin: 2 }}>
          <LinearProgressWithLabel value={parent.history.data.progress} />
        </Box>
      )}

      <GraphSlider
        style={{ marginTop: '1rem' }}
        value={range_slider_value}
        min={0}
        max={data.datasets[0].data.length}
        onChange={updateRangeSliderValue}
        onChangeCommitted={(event, value) => updateRange(value)}
      />

      <div id="legend-container" style={{ marginBottom: '30px' }} />
    </React.Fragment>
  );
}

function ExportDialog({ open, handleClose, controllerHistory, id }) {
  let { t } = useTranslation();
  const [time, setTime] = React.useState('DD.MM.YYYY HH:mm:ss');
  const [delimiter, setDelimiter] = React.useState(';');
  const [decimal, setDecimal] = React.useState(',');

  const UpdateDelimiter = (value) => {
    if (value === ',' && decimal === ',') setDecimal('.');
    setDelimiter(value);
  };

  const validateForm = (event) => {
    if (controllerHistory.time.length > 0) {
      let csv_content = 'data:text/csv;charset=utf-8,';
      let names = ['time'];
      names.push(...HISTORY_VALUES);
      csv_content += names.join(delimiter) + '\n';

      for (let i = 0; i < controllerHistory.time.length; i++) {
        for (const field of names) {
          if (field === 'time')
            csv_content +=
              momentWithLocale(controllerHistory[field][i]).utc().format(time) + delimiter;
          else if (field === 'ec_root_zone') csv_content += controllerHistory[field][i] + '\n';
          else csv_content += ParseFloat(controllerHistory[field][i], decimal) + delimiter;
        }
      }
      var encodedUri = encodeURI(csv_content);
      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', momentWithLocale().utc().format() + '_' + id + '.csv');
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('export')}</DialogTitle>
      <DialogContent>
        <label>{t('time_format')}: </label>
        <br />
        <TimeFormatSelect value={time} onChange={(event) => setTime(event.target.value)} />
        <br />
        <br />
        <label>{t('delimiter')}: </label>
        <br />
        <DelimiterSelect
          value={delimiter}
          onChange={(event) => UpdateDelimiter(event.target.value)}
        />
        <br />
        <br />
        <label>{t('decimal_format')}: </label>
        <br />
        <DecimalSelect
          value={decimal}
          delimiter={delimiter}
          onChange={(event) => setDecimal(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={validateForm} color="primary">
          {t('set')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function LinearProgressWithLabel(props) {
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          {props.value >= 0 && props.value <= 100 ? (
            <Typography variant="body2" color="text.secondary">
              {`${Math.round(props.value)}%`}
            </Typography>
          ) : (
            <Typography variant="body2" color="text.secondary">{`---%`}</Typography>
          )}
        </Box>
      </Box>
      {props.value === 0 && (
        <Typography value="body" color="text.secondary">
          Might be waiting for other data...
        </Typography>
      )}
    </React.Fragment>
  );
}

export const MemorizedGraph = React.memo(Graph);
